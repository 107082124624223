import styled from 'styled-components'

export const NotFoundContainer = styled.div`

  &:before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  &:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  height: 100vh;
  color: #fff;
  background: #010606;
`

export const NotFoundText = styled.h1`
  position: relative;
  top: 25%;
  font-size: 75px;
  text-align: center;
`

export const NotFoundToHome = styled.button`
  padding: 10px;
`