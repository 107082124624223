import styled from "styled-components"

export const TrackContainer = styled.div`
  background: rgb(15,12,41);
  background: linear-gradient(90deg, rgba(15,12,41,1) 0%, rgba(48,43,99,1) 50%, rgba(36,36,62,1) 100%);
  border: 1px solid transparent;
  opacity: 1;
`

export const TracklistWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1100px;
  margin: 80px auto 0 auto;
  padding: 0 24px 80px 24px;
  justify-content: center;
`


export const TrackHead = styled.h1`
  margin-top: 80px;
  color: #fff;
  font-size: 86px;
  text-align: center;

  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    font-size: 55px;
  }
`

export const Track = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 10px;
  color: white;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  margin-bottom: 20px;
  align-items: center;

  @media screen and (max-width: 786px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

`

export const Img = styled.img`
  grid-area: 1 / 1 / 4 / 3;
  width: 100px;
  height: 100px;
  margin: 10px;
  @media screen and (max-width: 768px){
    grid-area: 1 / 1 / 3 / 9;
    margin: 10px auto 0 auto;
  }
`

export const Name = styled.p`
  grid-area: 1 / 3 / 2 / 7;
  font-size: 1.3rem;

  @media screen and (max-width: 768px){
    grid-area: 3 / 1 / 4 / 9;
    text-align: center;
    font-size: 1.7rem;
  }
`

export const Author = styled.p`
  grid-area: 2 / 3 / 3 / 7; 

  @media screen and (max-width: 768px){
    grid-area: 4 / 1 / 5 / 9;
    text-align: center;
    font-size: 1.3rem;
  }
`

export const Album = styled.p`
  grid-area: 3 / 3 / 4 / 7;

  @media screen and (max-width: 768px){
    grid-area: 5 / 1 / 6 / 9;
    text-align: center;
    font-size: 1.3rem;
  }
`

export const Genre = styled.p`
  grid-area: 1 / 7 / 2 / 10;
  font-size: 0.7rem;

  @media screen and (max-width: 768px){
    grid-area: 6 / 1 / 7 / 4;
    text-align: center;
  }
`

export const Mood = styled.p`
  grid-area: 2 / 7 / 3 / 10;
  font-size: 0.7rem;

  @media screen and (max-width: 768px){
    grid-area: 6 / 4 / 7 / 7;
    text-align: center;
  }
`

export const LikeBtn = styled.div`
  grid-area: 3 / 7 / 4 / 10;

  @media screen and (max-width: 768px){
    grid-area: 6 / 7 / 7 / 9;
    text-align: center;
  }
`

export const LikeIcon = styled.img`
  height: 32px;
  width: 32px;
  margin: 0 auto 5px auto;
`

export const Musikplayer = styled.div`
  grid-area: 1 / 10 / 4 / 15;
  padding: auto;

  @media screen and (max-width: 768px){
    grid-area: 7 / 1 / 9 / 9;
  }
`


/*
  Layout für den einzelnen Track im Desptop Modus
  .parent {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }

  .div1 { grid-area: 1 / 1 / 4 / 3; } // Bild
  .div2 { grid-area: 1 / 3 / 2 / 7; } // Trackname
  .div3 { grid-area: 2 / 3 / 3 / 7; } // Autor
  .div4 { grid-area: 3 / 3 / 4 / 7; } // Albumname
  .div5 { grid-area: 1 / 7 / 2 / 10; } // Genre
  .div6 { grid-area: 2 / 7 / 3 / 10; } // Mood
  .div7 { grid-area: 3 / 7 / 4 / 10; } // Like Icon
  .div8 { grid-area: 1 / 10 / 4 / 15; } // MusikPlayer

  Layout für die Mobile Version
  .parent {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .div1 { grid-area: 1 / 1 / 3 / 9; }
  .div2 { grid-area: 3 / 1 / 4 / 9; }
  .div3 { grid-area: 4 / 1 / 5 / 9; }
  .div4 { grid-area: 5 / 1 / 6 / 9; }
  .div5 { grid-area: 6 / 1 / 7 / 4; }
  .div6 { grid-area: 6 / 4 / 7 / 7; }
  .div7 { grid-area: 6 / 7 / 7 / 9; }
  .div8 { grid-area: 7 / 1 / 9 / 9; }
*/