export const social_media = {
  title: 'Auf welcher Plattformen sind wir verteilt?',
  content: 'Instagram etc. Links findest du hier',
};

export const sessions = {
  title: 'Nehmt ihr Künstler auf?',
  content: 'JA! Du kannst uns einfach ein Song schicken oder ein Link',
};

export const donate = {
  title: 'Wie können wir euch unterstützen?',
  content: 'Lasst einfach ein kostenloses Abo da',
};

export const history = {
  title: 'Wie kam der Name RoJø zustande?',
  content: 'Eine zusammensetzung von ROxasMusic & AkanzJØ',
};

export const merch = {
  title: 'Gibt es Merch',
  content: 'Nein..',
};

export const genres = {
  title: 'Mit welcher Genre habt ihr die größte Erfahrung?',
  content: 'Electronic und Rap',
};

export const about = {
  title: 'Was ist überhaupt RoJø Records?',
  content: 'Wir sind ein digitales Musiklabel',
};

export const tools = {
  title: 'Was benutzt ihr für eure Musikproduktion?',
  content: 'FL Studio & Cubase',
};

export const example = {
  title: '',
  content: '',
};