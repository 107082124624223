import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const LoginContainer = styled.div`
  height: 100vh;
  color: #fff;
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`


export const LoginWrapper = styled.div`
  height: 350px;
  width: 600px;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  padding: 50px 35px;
  
  @media screen and (max-width: 768px) {
    height: 45%;
    width: 80%;
    padding: 20px 10px
  }
`

export const LoginBox = styled.div`
  
`

export const LoginTitle = styled.h1`
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;

    @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`

export const LoginField = styled.div`
  margin-top: 30px;
`

export const LoginEmail = styled.input`
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255,0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
`

export const LoginPass = styled.input`
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255,0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
`

export const Info = styled.p`
  text-align: center;
  font-size: 14px;
  margin-top: 15px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`

export const LoginSubmitt = styled.button`
  position: absolute;
  left: 5%;
  bottom: 10px;
  width: 90%;
  background-color: #ffffff;
  color: #080710;
  padding: 10px;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
`

export const LoginButtons = styled(LinkR)`
`

