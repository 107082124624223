import React from 'react'
import { LoginContainer, LoginWrapper, LoginBox, LoginTitle, Info, LoginSubmitt } from './RedirectElements';

export const RedirectPage = () => {

  return(
    <>
      <LoginContainer>
        <LoginWrapper>
          <LoginBox>
            <LoginTitle>Weiterleitung ist deaktiviert</LoginTitle>
            <Info>Hier sollte eine Weiterleitung zu einer Drittanbiter Seite erfolgen, aber derzeit ist der Dienst abgeschaltet</Info>
            <a href='https://rojorecords.de'>
              <LoginSubmitt href='https://rojorecords.de'>Zurück auf Startseite</LoginSubmitt>
            </a>
          </LoginBox>
        </LoginWrapper>
      </LoginContainer>
    </>
  )

}

export default RedirectPage;