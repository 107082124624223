import React, {useEffect} from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, CopyRight } from './FooterElements'
import { ExternalLink } from 'react-external-link'

const Footer = () => {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Links</FooterLinkTitle>
              <FooterLink to='/about'>Über uns</FooterLink>
              <FooterLink to='/impressum'>Impressum</FooterLink>
              <FooterLink to='/privacy'>Datenschutz</FooterLink>
              <FooterLink to='/services'>Services</FooterLink>
              <FooterLink>Coming Soon</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink onClick={() => openInNewTab('https://www.youtube.com/channel/UCKc6KOVuO854dR9dnyQrf_g')}>YouTube</FooterLink>
              <FooterLink onClick={() => openInNewTab('https://open.spotify.com/artist/4M8AS5kvIDkZej6vU8sOrZ')}>Spotify</FooterLink>
              <FooterLink onClick={() => openInNewTab('https://twitter.com/Akanzjo')}>Twitter</FooterLink>
              <FooterLink onClick={() => openInNewTab('https://www.instagram.com/rojo_records_offical/')}>Instagram</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <CopyRight>© RoJø Records 2024</CopyRight>
        <CopyRight>Mit "*" gekennzeichnete Links, führen auf eine Drittanbieter Seite.</CopyRight>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
