import React, {useState} from 'react';
import Blog from '../components-blog/Blog';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

export  const BlogPage = () => {

  {/*Checkt ob das Mobile Menü active ist.*/}
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  {/*Checkt ob der MiniPlayer active ist*/}
  const [isMusicOpen, setIsMusicOpen] = useState(false)

  const toggleMusic = () => {
    setIsMusicOpen(!isMusicOpen)
    console.log("Hi")
    console.log(isMusicOpen)
  }

  return(
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar isOpen={isOpen} toggle={toggle}/>
      <Blog />
      <Footer />
    </>
  )
}

export default BlogPage