import styled from "styled-components";

export const BlogContainer = styled.div`
  background: rgb(15,12,41);
  background: linear-gradient(90deg, rgba(15,12,41,1) 0%, rgba(48,43,99,1) 50%, rgba(36,36,62,1) 100%);
  border: 1px solid transparent;
  opacity: 1;
`

export const BlogWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 125px auto 0 auto;
  padding: 0 24px 80px 24px;
`

export const BlogTitle = styled.h1`
  text-align: center;
  font-size: 6rem;
  color: white;
  margin-bottom: 50px;
  text-decoration: underline;
  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`

export const BlogFrame = styled.div`
  display: block;
  color: white;
  padding: 20px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  border-radius: 10px;
  margin-bottom: 20px;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 50px;
  text-decoration: underline;
  @media screen and (max-width: 786px) {
    font-size: 2rem;
  }
`

export const BlogInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  margin-bottom: 30px;
  @media screen and (max-width: 786px) {
    font-size: 1.2rem;
  }

`

export const Author = styled.p`
`

export const Uploaded = styled.p`
  @media screen and (max-width: 768px) {
    text-align: end;
  }
`

export const Content = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 30px;
`

export const ImgWarpper = styled.div`
  display: flex;
  justify-content: center;
`

export const Img = styled.img`
  margin-bottom: 30px;
  height: 256px;
  width: 256px;
`

export const BlogLike = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LikeTxt = styled.p`
  font-size: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export const Like = styled.img`
  @media screen and (max-width: 768px) {
    scale: 0.7;
  }
`

export const BlogSite = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 50px;
`
/* Entfernt bis auf weiters
export const Left = styled.button`
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  border-radius: 10px;
  padding: 20px;
`
*/

export const Site = styled.div`
  margin: 0 20px 0 20px;
  opacity: 0.25;
`

export const SiteTxt = styled.p`
  padding: 0 auto 0 auto;
  font-size: 2rem;
`

/* Entfernt bis auf weiters
export const Right = styled.button`#
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  border-radius: 10px;
  padding: 20px;
`
*/