import styled from "styled-components";

export const OverviewContainer = styled.div`
  background: #010606;
  border: 1px;
  border-style: solid;
  border-color: #010606;
`

export const OverviewBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150%;
  overflow: hidden;
  z-index: 0;

  &:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(1,6,6,1) 0%, rgba(0,0,0,0.96) 14%, rgba(0,0,0,0.92) 30%, rgba(0,0,0,0.88) 70%, rgba(0,0,0,0.86) 100%);

  pointer-events: none;
  }
`

export const Img = styled.image`
  width: 1000%;
  height: 1000px;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  opacity: 1;
`

export const OverviewWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px 80px 24px;
  justify-content: center;
  text-align: center;
`

export const OverviewSearchSection = styled.div`

`

export const Searchbar = styled.input`

`

export const OverviewItemList = styled.ul`
  max-width: 1200px;
  height: 100%;
`

export const OverviewItem = styled.li`
  height: 350px;
  width: 175px;
`

export const ItemImage = styled.image`
  height: 100px;
  width: 100px;
`

export const ItemName = styled.h1`

`

export const ItemScortDesc = styled.p`

`

export const ItemPrice = styled.p`

`

export const ItemRating = styled.p`

`