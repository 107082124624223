import one from './../../images/studio.JPG'
import two from './../../images/trackScreen.png'

export const homeObjOne = {
  id: 'about',
  lightbg: false,
  lighttext: true,
  lightDesc: false,
  topline: 'Instrumentale für dich Personalisiert',
  heading: 'Was dein Herz begehrt',
  subtitle: 'Hier findest du bestimmt DEIN Beat.',
  buttonlable: 'Beatshop (LINK)',
  imgstart: false,
  img: one,
  alt: 'Keyboard',
  dark: true,
  primary: true,
  darktext: false
};

export const homeObjTwo = {
  id: 'about',
  lightbg: true,
  lighttext: false,
  lightDesc: false,
  topline: 'Unsere Tracks',
  heading: 'Alle unsere Tracks auf einem Blick',
  subtitle: 'Alle unsere Tracks auf einem Blick, hör dir eine 30 sekündige Version an.',
  buttonlable: 'Tracklist',
  imgstart: true,
  img: two,
  alt: 'AudioPlayer',
  dark: true,
  primary: true,
  darktext: true
};

export const homeObjThree = {
  id: 'about',
  lightbg: false,
  lighttext: true,
  lightDesc: false,
  topline: 'Beats',
  heading: 'Was dein Herz begehrt',
  subtitle: 'Hier findest du bestimmt DEIN Beat, und wenn nicht, erstellen wir gern für dich ein.',
  buttonlable: 'Tracklist',
  imgstart: false,
  img: require('../../images/keyboard.JPG').default,
  alt: 'Keyboard',
  dark: true,
  primary: true,
  darktext: false
};

