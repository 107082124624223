import React from "react";
import { social_media, sessions, donate, history, merch, genres, about, tools} from './FaqData.js'
import { AboutContainer, AboutWrapper, Headline, FaqWrapper, FaqBox, FaqList, FaqTitle, FaqContent, Text, TextContent, ContactWrapper, Mail } from './About';

const AboutPage = () => {


  return (
    <>  	
      <AboutContainer>
        <AboutWrapper>
          <Headline>FAQ Bereich</Headline>
            <FaqWrapper>
              <FaqBox>
                <FaqList>
                  <FaqTitle>
                    <Text>{social_media.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {social_media.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{sessions.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {sessions.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{donate.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {donate.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{history.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {history.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{merch.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {merch.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{genres.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {genres.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{about.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {about.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
                <FaqList>
                  <FaqTitle>
                    <Text>{tools.title}</Text>
                  </FaqTitle>
                  <FaqContent>
                    <TextContent>
                    {tools.content}
                    </TextContent>
                  </FaqContent>
                </FaqList>
              </FaqBox>
            </FaqWrapper>
          
          <Headline>Kontakt</Headline>
            <ContactWrapper>
              <Mail href="mailto:support@rojorecords.de">Schreib uns eine E-Mail</Mail>
            </ContactWrapper>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default AboutPage;