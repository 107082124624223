import styled, { keyframes } from "styled-components";

const fadeinout = keyframes`
  0% { opacity : 0;}
  50% { opacity: 0.50;}
  100% {opacity: 1;}
`

export const AboutContainer = styled.div`
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 100%;
  color: #fff;
  padding-top: 5%;
`


export const AboutWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 80px;
  justify-content: center;
  text-align: center;
`

export const Headline = styled.h1`
  color: #fff;
  font-size: 4rem;
`

export const FaqWrapper = styled.div`
`

export const FaqBox = styled.ul`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 25px 0 25px;
`

export const FaqList = styled.li`
  height: 100%;
  list-style-type: none;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  //justify-content: space-between;
  //align-items: center;
  margin-bottom: 50px;
`

export const FaqTitle = styled.div`
  text-align: center;
  width: 100%;
  height: 60px;
  
  @media screen and (max-width: 786px){
    margin-bottom: 10px;
  }
`

export const FaqContent = styled.div`
  display: block;
  width: 100%;
  height: 75px;
  border-top: 2px solid white;
` 

export const Text = styled.h2 `

`

export const TextContent = styled.p`
  font-size: 1.2rem;
`

export const Expand = styled.div`
  border: 2px solid white;
  width: 25px;
  height: 25px;
  margin-right: 50px;
`

//Conatct Area

export const ContactWrapper = styled.div`

`

export const Mail = styled.a`
  font-size: 1.5rem;
  color: white;
`