import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import { NavLogo } from '../components/Navbar/NavbarElements'
import { NotFoundContainer, NotFoundText, NotFoundToHome } from './NotFound'

export const NotFoundElements = () => {
  return(
    <>
      <NotFoundContainer>
        <NavLogo to='/home'>Zur Website</NavLogo>
        <NotFoundText>
          404
        </NotFoundText>
        <NotFoundText>
          Seite nicht gefunden!
        </NotFoundText>
      </NotFoundContainer>
    </>
  )
}

export default NotFoundElements