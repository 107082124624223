import React from "react";
import { DashboardContainer, DashboardWrapper, DashboardTitle, DashboardButtonGroup, DashboardButton } from "./DashboardElements";

function Dashboard() {
  return(
    <DashboardContainer>
      <DashboardWrapper>
        <DashboardButtonGroup>
          <DashboardTitle>Menü</DashboardTitle>
          <DashboardButton to='/dashboard/trackadd'>Track hinzufügen</DashboardButton>
          <DashboardButton to='/dashboard/trackupdate'>Trackinfos ändern</DashboardButton>
          <DashboardButton to='/dashboard/trackdelete'>Track löschen</DashboardButton>
          <DashboardButton to='/'>Startseite</DashboardButton>
        </DashboardButtonGroup>
      </DashboardWrapper>
    </DashboardContainer>
  )
}

export default Dashboard;