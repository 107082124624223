import styled from "styled-components";
import { Link } from "react-router-dom";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  background: #010606;
`

export const DashboardWrapper = styled.div`
  max-width: 1200px;
  height: 100%;
`

export const DashboardButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1200px;
`

export const DashboardTitle = styled.h1`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 75px;
`

export const DashboardButton = styled(Link)`
  height: 75px;
  width: 350px;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  justify-content: center;

  &:hover {
    background-color: #383838;
  }
`