import React from 'react'
import { Button } from '../ButtonElements'
import bild from './../../images/trackScreen.png'
//import { useHistory } from "react-router-dom";
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'

const InfoSection = ( {lightbg, id, lighttext, imgstart, topline, heading, subtitle, buttonlable, darktext, img, alt} ) => {

  /*const history = useHistory();

  const navigateToTracklist = () => {
    let path = `/tracklist`; 
    history.push(path);
  }*/

  return (
    <InfoContainer lightbg={lightbg} id={id}>
      <InfoWrapper>
        <InfoRow imgstart={imgstart}>
          <Column1>
            <TextWrapper>
              <TopLine>
                {topline}
              </TopLine>
              <Heading lighttext={lighttext}>
                {heading}
              </Heading>
              <Subtitle darktext={darktext}>
                {subtitle}
              </Subtitle>
              <BtnWrap>
                <Button /*onClick={navigateToTracklist}*/ >{buttonlable}</Button>
              </BtnWrap>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={img} alt={alt}/>
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  )
}

export default InfoSection
