import styled from "styled-components";
import {Link} from 'react-router-dom'
import { IoIosMore } from "react-icons/io"

export const TrackContainer = styled.div`
  background: #010606;
  justify-content: center;
  align-items: center;
`

export const MoreTracksBtn = styled(IoIosMore)`
  background-color: #010606;
  width: 300px;
  height: 60px;
  margin: auto;
  cursor: pointer;
`

export const MoreTracksLink = styled.a`
  width: 100%;
  height: 100%;
  background-color: #fff;
`

export const MoreTracksLable = styled.p`
  font-size: 25px;
  text-align: center;
  padding-bottom: 15px;
`

export const TracklistWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px 50px;
`

export const TrackList = styled.ul`
  position: relative;
  top: 30px;
  height: 100%;

`

export const TrackHead = styled.h1`
  color: #fff;
  font-size: 86px;
  text-align: center;
`

export const TrackTop3 = styled.h2`
  color: #fff;
  color: 70px;
  text-align: center;
`

export const Track = styled.li`
  margin: 27px 10px 0px 10px;
  height: 100px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);

  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    height: 255px;
  }
  
`

export const Img = styled.img`
  margin: 10px;
  width: 80px;
  height: 80px;
  float: left;

  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
`

export const TrackName = styled.h1`
  font-size: 24px;
  color: #fff;
  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    font-size: 20px;
    text-align: center;
  }
`

export const TrackAuthor = styled.p`
  font-size: 16px;
  color: #fff;
  @media screen and (max-width: 768px) {
    transition: 0.8s all ease;
    font-size: 14px;
    text-align: center;
  }
`