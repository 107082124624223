import {React, useState, useEffect} from "react";
import axio from '../../configs/axio';
import requestURL from '../../configs/request';
import { BlogContainer, BlogWrapper, BlogTitle, BlogFrame, Title, BlogInfo, Author, Uploaded, Content, ImgWarpper, Img, BlogLike, LikeTxt, Like, BlogSite, Left, Site, SiteTxt, Right} from "./BlogElement";
import studio from '../../images/full_heart.png'
import liked from '../../images/full_heart.png'
import { hostAdress } from "../../configs/hostadress";

function Blog() {
  const [blogEntry, setBlogEntry] = useState([])
  const [totalBlogEntrys, setTotalEntry] = useState()
  const [rangeOfBlog, setRangeOfBlogs] = useState()

  useEffect(() => {
    async function fetchData() {
      const request = await axio.get(requestURL.blogEntry, {start_id: 1, blogsPerPage: 10});
      console.log(request.data)
      setBlogEntry(request.data)
    }
    fetchData()
  }, [])

  return(
    <>
       <BlogContainer>
        <BlogWrapper>
          <BlogTitle>RoJø's Blog</BlogTitle>
          {blogEntry.map((val, key) => {
            return <div>          
              <BlogFrame>
                <Title>{val.title}</Title>
                <BlogInfo>
                  <Author>{'Autor: ' + val.author}</Author>
                  <Uploaded>{'Hochgeladen: ' + val.uploaded}</Uploaded>
                </BlogInfo>
                <Content>{val.content}</Content>
                <ImgWarpper>
                  <Img src={'http://' + hostAdress.adress + ':' + hostAdress.port + '/image/' + val.image}/>
                </ImgWarpper>
                <BlogLike>
                  <LikeTxt>{'Likes: ' + val.liked}</LikeTxt>
                  <Like src={liked}/>
                </BlogLike>
              </BlogFrame></div>
          })}
          <BlogSite>
            <Site>
              <SiteTxt>Ende der Seite...</SiteTxt>
            </Site>
          </BlogSite>
        </BlogWrapper>
       </BlogContainer>
    </>
  )
}

export default Blog