import { React, Component} from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/index'
import TrackPage from './pages/TrackPage';
import About from './pages/About';
import Login from './pages/Login';
import Impressum from './pages/Impressum';
import Privacy from './pages/Privacy';
import Overview from './pages/Overview';
import Detail from './pages/Detail';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotFound from './pages/NotFound';
import Redirect from './pages/Redirect';
import Dashboard from './pages/Dashboard';
import Axios from 'axios';
import {hostAdress} from './configs/hostadress'
import BlogPage from './pages/Blog';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <NotFound />
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/tracklist',
    element: <TrackPage />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/signin',
    element: <Login />,
  },
  {
    path: '/impressum',
    element: <Impressum />,
  },
  {
    path: '/datenschutz',
    element: <Privacy />,
  },
  {
    path: '/blog',
    element: <BlogPage />,
  },
  {
    path: '/detail',
    element: <Detail />,
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '/signup',
    element: <NotFound />,
  },
  {
    path: '/dash',
    element: <Dashboard />,
  },
])

// Scroll to top when page changes
function ScrollToTop() {
  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}


function App() {

  const host = hostAdress

  /*function newVisit() {
    Axios.post('http://' + host.adress + ':3002/new-visit').then(resp => {
      console.log("hat geklappt")
    })
    console.log("App.js")
  }

  useEffect(() => {
    newVisit()
  }, [])*/


  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
