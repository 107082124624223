import React, { useState, useEffect } from 'react'
import {TrackContainer, TracklistWrapper, TrackHead, Track, Img, Name, Author, Album, Genre, Mood, LikeBtn, Musikplayer, TrackBg, BgImg, LikeIcon} from './TracklistElements'
import MusikPlayer from '../../components/MusikPlayer'
import axio from '../../configs/axio';
import { hostAdress } from '../../configs/hostadress';
import requestURL from '../../configs/request';
import fullHeart from '../../images/full_heart.png';
import emptyHeart from '../../images/empty_heart.png';
import "./style.css";

function Tracklist() {

  const [tracklist, setTracklist] = useState([])
  const [liked, setLiked] = useState(false)


  const onLiked = () => {
    setLiked(!liked)
  }

  
  useEffect(() => {
    async function fetchData() {
      const request = await axio.get(requestURL.trackList);
      setTracklist(request.data)

      return request;
    }
    fetchData()

  }, [])

  return(
    <TrackContainer class="anim">
        <TracklistWrapper>
          <TrackHead>TRACKLIST</TrackHead>
            {tracklist.map((val, key)=>{
              return <div>
                <Track>
                  <Img alt="Bild vom Track" src={'http://' + hostAdress.adress + ':' + hostAdress.port + '/image/' + val.image}/>
                  <Name>{val.name}</Name>
                  <Author>{val.author}</Author>
                  <Album>{val.album}</Album>
                  <Genre>Genre: {val.genre}</Genre>
                  <Mood>Mood: {val.mood}</Mood>
                  <LikeBtn onClick={onLiked}>{liked ? <LikeIcon src={emptyHeart}/> : <LikeIcon src={fullHeart}/>}</LikeBtn>
                  <Musikplayer>
                    <MusikPlayer  musicSource={'http://' + hostAdress.adress + ':' + hostAdress.port + '/track/' + val.source} id={val.id}></MusikPlayer>
                  </Musikplayer>
                </Track></div>
            })}
        </TracklistWrapper>
    </TrackContainer>
  )
}


export default Tracklist
